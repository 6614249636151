import PgHomeScrGeneric from "./entities/pgHomeScrGeneric";
import PgHomeScrProducts from "./entities/pgHomeScrProducts";
import SectionMnger from "./entities/screenMnger";
import Header from "./entities/header";
import Langs from "./data/langs";
import Products from "./data/products";
import Loader, { LoaderEvents } from "./loader";
import PgHomeScrMap from "./entities/pgHomeScrMap";
import templates from "bundle-text:../templates/ts-home.html";
import { appUtils } from ".";
import { AppBase } from "./appBase";

class AppHome extends AppBase {
    private _pageMnger: SectionMnger;
    private _productsPage: PgHomeScrProducts;
    private _products: Products;
    private _templates: { [tag: string]: string; };

    get loader() { return this._loader; }
    get pageMnger() { return this._pageMnger; }
    get productsPage() { return this._productsPage; }
    get header() { return this._header; }
    get langs() { return this._langs; }
    get products() { return this._products; }
    get templates() { return this._templates; }

    constructor() {
        super();
        this._loader = new Loader();
        this._products = new Products(this._loader);
        this._header = new Header(this);
        this._templates = appUtils.tagsToStrMap(templates);
    }

    override _init() {
        this._langs.init();
        this._header.init();

        const elmList = document.body.querySelectorAll<HTMLElement>(":scope>section");
        this._pageMnger = new SectionMnger([
            new PgHomeScrGeneric(elmList[0]),
            new PgHomeScrGeneric(elmList[1]),
            this._productsPage = new PgHomeScrProducts(elmList[2]),
            new PgHomeScrMap(elmList[3]),
        ]);

        this._loader.once(LoaderEvents.Complete, () => this._onLoadDone())
            .load(false);
    }

    private _onLoadDone() {
        window.addEventListener("mousemove", e => this._onMouseMove(e));

        this._pageMnger.init();
        this._loader.once(LoaderEvents.Complete, () => {
            document.documentElement.classList.remove("loading");
            this._pageMnger.showPage(0, true);
        }).load();
    }
}

export const app = new AppHome();