import { appUtils } from "..";
import { app } from "../appHome";

export default class PgHomeScrProductsMenu {
    private _cont: HTMLElement;
    private _items: NodeListOf<HTMLLIElement>;
    private _catId: string;

    get cont() { return this._cont; }
    get items() { return this._items; }

    constructor() {
        this._cont = app.productsPage.cont.querySelector(".home-product-cat-nav");

        (this._items = this._cont.querySelectorAll<HTMLLIElement>(":scope>ul>li"))
            .forEach((item, index) => {
                if (app.productsPage.list.find(match => match.data.categoryId == item.dataset["id"]))
                    item.addEventListener("click", () => app.productsPage.showProduct(index));
                else
                    item.parentElement.removeChild(item);
            });
    }

    change(catId: string) {
        if (appUtils.nullOrEmpty(this._catId) && this._catId == catId)
            return;

        this._items.forEach((item, i) => {
            item.classList.remove("selected");
            if (item.dataset["id"] == catId) {
                app.productsPage.showProduct(i);
                item.classList.add("selected");
            }
        });
    }
}