export const CategoryNames = {
    one: "one", duo: "duo", mix: "mix", ice: "ice", special: "special", color: "color"
}
export type TypeDirFactor = 1 | 0 | -1;
export type TypeTheme = "light | dark";

export type TypeMapPlace = {
    x: number,
    y: number,
    headOffice?: boolean
};

export type TypeDataLangs = {
    [langId: string]: {
        id: string,
        ingredients: {
            [ingredientId: string]: {
                title: string,
            }
        },
        categories: {
            [catId: string]: {
                title: string,
            }
        },
        products: {
            [productId: string]: {
                title: string,
                subtitle: string,
                summary: string,
                desc: string,
            }
        },
        places?: {
            [key: string]: {
                country: string,
                city: string,
                address: string,
            }
        }
        customs?: {
            [key: string]: any
        }
    }
}

export type TypeDataCategory = {
    id: string,
    bg_home?: boolean,
    bg_detail_str?: string,
    bg_detail_img?: boolean,
    bg_detail_glow?: boolean,
    theme?: TypeTheme
};

export type TypeDataIngredient = {
    id: string,
    color: string,
};

export type TypeDataProductIngredient = {
    id: string,
    percent: number,
}

export type TypeDataProductBgIngredient = {
    image: string,
    x: number,
    y: number,
    width: number,
    layer?: number,
}

export type TypeDataProduct = {
    id: number,
    categoryId: string,
    ingredients: TypeDataProductIngredient[],
    feature_image_width: number,
    releateds: number[],
    bg_home?: boolean,
    bg_detail_str?: string,
    bg_detail_img?: boolean,
    bg_detail_glow?: boolean,
    bg_ingredients: TypeDataProductBgIngredient[],
    theme?: TypeTheme
};

export type TypeDataProducts = {
    ingredients: {
        [ingId: string]: TypeDataIngredient
    },
    categories: {
        [catId: string]: TypeDataCategory
    },
    products: TypeDataProduct[]
};