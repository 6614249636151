import anime from "animejs";
import { configs } from "..";
import ScreenBase from "./screenBase";

export default class PgHomeScrGeneric extends ScreenBase {
    protected _animElms: NodeListOf<HTMLElement>;

    constructor(container: HTMLElement) {
        super(container);
        this._animElms = this._cont.querySelectorAll<HTMLElement>("*[data-anim], *[data-anim-rotate]");
    }

    protected _onShow(dirFactor: 0 | 1 | -1, complete?: Function, endDelay?: number): void {
        super._onShow(dirFactor, complete, endDelay);

        anime({
            targets: this._animElms,
            opacity: [0, 1],
            translateY: (el) => {
                return el.dataset['animRotate'] ? undefined : ["4vh", 0]
            },
            rotate: (el) => {
                return el.dataset['animRotate'] ? el.dataset['animRotate'].split(",") : undefined
            },
            easing: "easeInOutSine",
            duration: configs.animDurPageChildrenShared,
            delay: anime.stagger(75, { start: dirFactor == 0 ? 0 : configs.animDurPageChange * .5 }),
        });
    }

    protected _onHide(dirFactor: 0 | 1 | -1, onstart?: Function, complete?: Function): boolean {
        const canHide = super._onHide(dirFactor, onstart, complete, configs.animDurPageChildrenShared * .5);

        if (canHide) {
            anime({
                targets: this._animElms,
                opacity: 0,
                translateY: (el) => {
                    return el.dataset['animRotate'] ? undefined : (dirFactor * -25 + "vh")
                },
                rotate: (el) => {
                    return el.dataset['animRotate'] ? (-1 * parseInt(el.dataset['animRotate'].split(",")[0])) : undefined
                },
                easing: "easeInOutSine",
                duration: configs.animDurPageChildrenShared,
                delay: anime.stagger(40, { direction: dirFactor == -1 ? "reverse" : "normal" }),
            });
        }

        return canHide;
    }
}