import { mapPlaces } from "../data/mapPlaces";
import PgHomeScrGeneric from "./pgHomeScrGeneric";
import { app } from '../appHome';
import { appUtils, configs } from "..";

export default class PgHomeScrMap extends PgHomeScrGeneric {

    private _dotsCont: HTMLElement;
    private _dots: HTMLElement[];

    constructor(container: HTMLElement) {
        super(container);
    }

    init(): void {
        super.init();

        this._dotsCont = this._cont.querySelector(".map-wrap");
        this._populateItems();
    }

    private _populateItems() {
        this._dots = [];
        mapPlaces.forEach(item => {
            let elm = appUtils.stringToHTML(app.templates["map-item"]
                .replace("{headOffice}", item.headOffice ? 'yes' : 'no')
            );
            elm.style.left = item.x / configs.defMapWidth * 100 + "%";
            elm.style.top = item.y / configs.defMapHeight * 100 + "%";
            this._dots.push(elm);
            this._dotsCont.append(elm);
        });
    }
}