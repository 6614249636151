import { TypeMapPlace } from "."

export const mapPlaces: TypeMapPlace[] = [
    //{ x: 513, y: 655 },
    //{ x: 567, y: 607 },
    { x: 1008, y: 203 },
    { x: 874, y: 519 },
    { x: 891, y: 168 },
    { x: 955, y: 167 },
    { x: 816, y: 263 },
    { x: 853, y: 263 },
    { x: 870, y: 236 },
    { x: 977, y: 249 },
    { x: 946, y: 283 },
    { x: 939, y: 297 },
    { x: 960, y: 305, headOffice: true },
    { x: 926, y: 325 },
    { x: 977, y: 353 },
    { x: 1032, y: 301 },
    { x: 1067, y: 311 },
    { x: 1049, y: 359 },
    { x: 1109, y: 261 },
    { x: 1136, y: 325 },
    { x: 1145, y: 305 },
    { x: 1169, y: 297 },
    { x: 1173, y: 357 },
    { x: 1219, y: 325 },
    { x: 1453, y: 347 },
];