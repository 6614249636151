import anime from "animejs";
import { configs } from "..";
import { TypeDirFactor } from "../data";

export default class ScreenBase {

    protected _cont: HTMLElement;
    protected _scrollCont: HTMLElement;
    protected _displayStyle: string;
    protected _btnClose: HTMLElement;
    protected _animating: boolean;
    protected _isScrolling: boolean;

    get cont() { return this._cont; }
    get animating() { return this._animating; }
    get pageChangeSelfControl() { return false; }
    get scrollCont() { return this._scrollCont; }

    constructor(container: HTMLElement, displayStyle: string = "block") {
        this._displayStyle = displayStyle;
        this._cont = container;
        this._cont.style.display = "none";

        this._scrollCont = this._cont.querySelector(".scroll-container") || this._cont;
        this._scrollCont.addEventListener('scroll', e => {
            if (this._animating)
                e.preventDefault();
        })
        this._scrollCont.addEventListener('touchmove', e => {
            if (this._animating)
                e.preventDefault();
        })
    }

    init() {

    }

    show(dirFactor: TypeDirFactor, onComplete?: Function) {
        this._onShow(dirFactor, onComplete);
    }

    protected _onShow(dirFactor: TypeDirFactor, onComplete?: Function, endDelay: number = 0) {
        if (this.pageChangeSelfControl && dirFactor == 0)
            return;

        this._cont.style.display = this._displayStyle;
        this._animating = true;
        this.scrollCont.scrollTop = 0;
        if (dirFactor == 0) {
            this._cont.style.top = "0px";
            anime({
                targets: this._cont, opacity: [0, 1],
                duration: configs.animDurPageOpacityFade, easing: "linear",
                endDelay, complete: () => {
                    this._animating = false;
                    onComplete && onComplete();
                }
            });
            return;
        }

        anime({
            targets: this._cont, top: [dirFactor * 100 + "%", 0],
            duration: configs.animDurPageChange, easing: "easeInOutSine",
            endDelay, complete: () => {
                this._animating = false;
                onComplete && onComplete();
            }
        });
    }

    hide(dirFactor: TypeDirFactor, onStart?: Function, onComplete?: Function): boolean {
        return this._onHide(dirFactor, onStart, onComplete);
    }

    protected _onHide(dirFactor: TypeDirFactor, onStart: Function,
        onComplete: Function, startDelay: number = 0): boolean {

        if (this.pageChangeSelfControl && dirFactor == 0)
            return;

        if (dirFactor == 0) {
            this._animating = true;
            anime({
                targets: this._cont, opacity: 0,
                duration: configs.animDurPageOpacityFade, easing: "linear",
                delay: startDelay,
                changeBegin: () => {
                    onStart && onStart();
                },
                complete: () => {
                    this._animating = false;
                    this._cont.style.display = "none";
                    onComplete && onComplete();
                }
            });
            return true;
        }

        if (!this._canHide(dirFactor))
            return false;

        this._animating = true;
        anime({
            targets: this._cont, top: dirFactor * -100 + "%",
            duration: configs.animDurPageChange, easing: "easeInOutSine",
            delay: startDelay,
            changeBegin: () => {
                onStart && onStart();
            },
            complete: () => {
                this._animating = false;
                this._cont.style.display = "none";
                onComplete && onComplete();
            }
        });
        return true;
    }

    protected _canHide(dirFactor: TypeDirFactor) {
        const scont = this.scrollCont;
        const sh = scont.scrollHeight - scont.offsetHeight;

        if (!this.animating/*  && (
            scont.scrollHeight == 0 ||
            (dirFactor == -1 && scont.scrollTop == 0) ||
            (dirFactor == 1 && scont.scrollTop >= sh - 1)
        ) */) {
            return true;
        }

        return false;
    }
}