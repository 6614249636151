import { appUtils } from "..";
import ScreenBase from "./screenBase";
import Swiper, { SwipeDirs, SwiperEvents } from "./swiper";

export default class SectionMnger {
    private _list: ScreenBase[];
    private _index: number = -1;
    private _swiper: Swiper;

    get list() { return this._list; }
    get index() { return this._index; }
    get swiper() { return this._swiper; }

    constructor(list: ScreenBase[]) {
        this._list = list;
    }

    init() {
        this._list.forEach(item => item.init());

        window.addEventListener("wheel", (e) => this._onMouseWheel(e), { passive: false });
        (this._swiper = new Swiper(document.body, { cursorHand: false, onlyTouch: true, thresholdTime: 300 }))
            .on(SwiperEvents.SwipeEnd, e => {
                if (e.dir == SwipeDirs.Up || e.dir == SwipeDirs.Down)
                    this.showPage(this._index + (e.dir == SwipeDirs.Up ? -1 : 1));
            });
    }

    showPage(index: number, force: boolean = false): boolean {
        if (!force && document.querySelector<HTMLElement>(".over-18-container"))
            return;

        const newIndex = appUtils.clamp(index, 0, this._list.length - 1);
        if (this._index == newIndex && (this._index > -1 && !this._list[this._index].pageChangeSelfControl))
            return false;

        const dir = this._index < 0 ? 0 : (newIndex - this._index < 0 ? -1 : 1);

        const showNewPage = () => {
            this._list[this._index = newIndex].show(dir);
            this._swiper.setActiveScrollContainer(this._list[newIndex].scrollCont);
        }

        if (this._index > -1) {
            if (!this._list[this._index].hide(dir, showNewPage))
                return false;
        }
        else
            showNewPage();

        return true;
    }

    private _onMouseWheel(e: WheelEvent) {
        if (Math.abs(e.deltaY) > 75) {
            this.showPage(this._index + (e.deltaY < 0 ? -1 : 1));
            e.preventDefault();
        }
    }
}