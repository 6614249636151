import anime from "animejs";
import { appUtils, configs } from "..";
import { CategoryNames, TypeDirFactor } from '../data/index';
import PgHomeProduct from "./pgHomeProduct";
import PgHomeScrProductsMenu from "./pgHomeScrProductsMenu";
import ScreenBase from "./screenBase";

export default class PgHomeScrProducts extends ScreenBase {

    private _list: PgHomeProduct[] = [];
    private _menu: PgHomeScrProductsMenu;
    private _productCont: HTMLElement;
    private _index: number = -1;

    get menu() { return this._menu; }
    get index() { return this._index; }
    get list() { return this._list; }
    get productCont() { return this._productCont; }
    get pageChangeSelfControl() { return true; }

    constructor(container: HTMLElement) {
        super(container);

        this._productCont = container.querySelector(".product-section");
    }

    init() {
        super.init();

        this._list = [
            new PgHomeProduct(CategoryNames.one),
            new PgHomeProduct(CategoryNames.duo),
            new PgHomeProduct(CategoryNames.mix),
            new PgHomeProduct(CategoryNames.ice),
            new PgHomeProduct(CategoryNames.special),
            new PgHomeProduct(CategoryNames.color),
        ];

        this._menu = new PgHomeScrProductsMenu();
        this.showProduct(0);
    }

    protected _onShow(dirFactor: TypeDirFactor, complete?: Function, endDelay?: number): void {
        super._onShow(dirFactor, complete, endDelay);

        this._list[this._index].show(0);

        anime({
            targets: [
                this._list[this._index].cont.querySelectorAll(".product-texts"),
                this._menu.items,
            ],
            opacity: [0, 1], translateY: ["4vh", 0], easing: "easeInOutSine",
            duration: configs.animDurPageChildrenShared,
            delay: anime.stagger(75, { start: dirFactor == 0 ? 0 : configs.animDurPageChange * .5 }),
        });
    }

    protected _onHide(dirFactor: TypeDirFactor, onstart: Function, onComplete: Function, startDelay?: number): boolean {

        if (this._index >= 0 && this._list[this._index].animating)
            return false;

        if (this._index + dirFactor >= 0 && this._index + dirFactor < this._list.length) {
            if (this._canHide(dirFactor))
                this.showProduct(this._index + dirFactor);

            return false;
        }

        anime({
            targets: [
                this._menu.items,
                this._list[this._index].cont.querySelectorAll(".product-texts"),
            ],
            opacity: 0, translateY: dirFactor * -25 + "vh", easing: "easeInOutSine",
            duration: configs.animDurPageChildrenShared,
            delay: anime.stagger(40, { direction: dirFactor == -1 ? "reverse" : "normal" }),
        });

        this._list[this._index].hide(0);

        return super._onHide(dirFactor, onstart, onComplete, (startDelay || 0) + 150);
    }

    showProduct(index: number) {
        const newIndex = appUtils.clamp(index, 0, this._list.length - 1);
        if (newIndex == this._index)
            return;

        const dir = this._index < 0 ? 0 : (newIndex - this._index < 0 ? -1 : 1);

        if (this._index > -1) {
            if (this._list[this._index].animating || this._list[newIndex].animating)
                return false;

            this._list[this._index].hide(dir);
        }

        this._index = newIndex;
        this._list[this._index].show(dir);
        this._menu.change(this._list[this._index].data.categoryId);

        // setTimeout(() => this._scrollCont.scrollTop = 0, 100);
        return true;
    }
}