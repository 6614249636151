import { appUtils } from "..";
import { app } from "../appHome";
import { LangsEvents } from "../data/langs";
import ProductBase from "./productBase";

export default class PgHomeProduct extends ProductBase {
    constructor(catId: string) {
        super();

        this._data = this._getRandomProduct(catId);
        if (!this._data || !this._data.id)
            return;

        this._catData = app.products.data.categories[this._data.categoryId];
        const lang = app.langs.lang.products[this._data.id.toString()],
            langCat = app.langs.lang.categories[this._data.categoryId];

        this._cont = appUtils.stringToHTML(app.templates["product"]
            .replace(/\{id\}/g, this._data.id.toString())
            .replace(/\{cat-id\}/g, this._data.categoryId)
            .replace("{title}", lang.title)
            .replace("{subtitle}", lang.subtitle)
            .replace("{summary}", lang.summary)
            .replace("{category}", langCat.title)
            .replace("{box-glow-path}", this._data.bg_detail_glow ? `${this._data.categoryId}/${this._data.id}/` : this._data.categoryId)
        );

        this._setBackground();

        //this._imgFeature = this._cont.querySelector(".product-img-feature");
        //this._setImgFeature();

        this._boxes = this._cont.querySelectorAll(".product-box");

        this._bgIngredients = this._cont.querySelector(".product-parallax");
        this._fillBgIngredients();

        this._addParallax(app);
        appUtils.dataHrefToHref(this._cont);

        this._cont.style.display = "none";
        app.productsPage.productCont.insertAdjacentElement("afterbegin", this._cont);
        app.langs.on(LangsEvents.Changed, this._onLangChange.bind(this));

        this.addImagesToLoad(app.loader);
        
        this._animating = false;
    }

    override _onLangChange(): void {
        this._cont.querySelectorAll<HTMLElement>("[data-lang-text]").forEach(item => {
            if (item.dataset["langText"] == "category")
                item.textContent = app.langs.lang.categories[this._data.categoryId].title;
            else
                item.textContent = app.langs.lang.products[this._data.id][item.dataset["langText"]];
        });
    }

    private _getRandomProduct(catId: string) {
        return app.products.byCat[catId][appUtils.rand({ max: app.products.byCat[catId].length, toInt: true })];
    }
}